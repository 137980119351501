import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import useServiceCardControl, {
  Props,
  ReceivedProps,
  TAB_1,
  TAB_2,
  TAB_3,
  TAB_4,
  TAB_5,
  TAB_7,
} from "./hook";
import { Styles } from "./styled";
import OrderInformation from "./OrderInformation";
import Status from "./Status";
import ContractorInformation from "./ContractorInformation";
import PaymentInformation from "./PaymentInformation";
import WorkReport from "./WorkReport";
import WorkCost from "./WorkCost";
import Memo from "./Memo";
import RequestContent from "./RequestContent";
import { Loading, ModalConfirm, openNotification } from "@bms/components";
import { QUERY_NAME, ROUTES } from "@bms/constants";
import { NotificationSmWhiteIcon } from "@bms/assets";
import ReactRouterPrompt from "react-router-prompt";
import { useQueryClient } from "@tanstack/react-query";

const ServiceCardControlLayout: FC<Props> = ({
  isView,
  id,
  valueTab,
  control,
  isLoading,
  serviceCardData,
  workerOptions,
  phoneOptions,
  receptionOptions,
  subcontractorOptions,
  errors,
  messages,
  isSubmitting,
  paymentCategoryData,
  workersData,
  workersDataOriginal,
  workersOfficeDataOriginal,
  filterKey,
  navigatePrompt,
  isWorkerLoading,
  handleError,
  setError,
  clearErrors,
  setRequestOrderDate,
  setRequestStartDate,
  setRequestEndDate,
  setFilterKey,
  setMessages,
  watch,
  reset,
  register,
  setValue,
  getValues,
  handleChange,
  handleSubmit,
  onSubmit,
  trigger,
  isRedirectFromBuilding,
  setEmployeeInChargeId,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const props = {
    serviceCardData,
    control,
    isView,
    errors,
    workersData,
    workersDataOriginal,
    workersOfficeDataOriginal,
    isSubmitting,
    watch,
    reset,
    register,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    onSubmit,
  };

  useEffect(() => {
    if (navigatePrompt === "auto") {
      openNotification();
      navigate(ROUTES.SERVICE_CARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigatePrompt]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Styles>
      <div className="header">
        <h3>
          {!id
            ? "サービスカード登録"
            : isView
            ? "サービスカード詳細"
            : "サービスカード編集"}
        </h3>
        <div className="group-btn">
          <button
            className="btn-back"
            onClick={() => {
              navigate(ROUTES.SERVICE_CARD);
              queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
            }}
          >
            戻る
          </button>
          {!isView && (
            <LoadingButton
              loadingPosition="start"
              loading={isSubmitting}
              type="submit"
              variant="outlined"
              className="btn-submit"
              onClick={handleSubmit(onSubmit)}
            >
              {!id ? "追加" : "更新"}
            </LoadingButton>
          )}
        </div>
      </div>
      <div className="tabs-wrapper">
        <div
          style={{
            width: "fit-content",
          }}
        >
          <TabContext value={valueTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="tabs"
                className="tabs"
              >
                <Tab
                  value="1"
                  label={
                    <div className="tab-label-wrapper">
                      <p>対応</p>
                      {serviceCardData?.status?.has_notification && (
                        <img src={NotificationSmWhiteIcon} alt="" />
                      )}
                      {handleError(TAB_1) && (
                        <ErrorOutlineIcon htmlColor="#FF0000" />
                      )}
                    </div>
                  }
                />
                <Tab
                  value="2"
                  label={
                    <div className="tab-label-wrapper">
                      <p>発注者情報</p>
                      {serviceCardData?.order_information?.has_notification && (
                        <img src={NotificationSmWhiteIcon} alt="" />
                      )}
                      {handleError(TAB_2) && (
                        <ErrorOutlineIcon htmlColor="#FF0000" />
                      )}
                    </div>
                  }
                />
                <Tab
                  value="3"
                  label={
                    <div className="tab-label-wrapper">
                      <p>受注者情報</p>
                      {serviceCardData?.contractor_information
                        ?.has_notification && (
                        <img src={NotificationSmWhiteIcon} alt="" />
                      )}
                      {handleError(TAB_3) && (
                        <ErrorOutlineIcon htmlColor="#FF0000" />
                      )}
                    </div>
                  }
                />
                <Tab
                  value="4"
                  label={
                    <div className="tab-label-wrapper">
                      <p>依頼内容</p>
                      {serviceCardData?.request_content?.has_notification && (
                        <img src={NotificationSmWhiteIcon} alt="" />
                      )}
                      {handleError(TAB_4) && (
                        <ErrorOutlineIcon htmlColor="#FF0000" />
                      )}
                    </div>
                  }
                />
                <Tab
                  value="5"
                  label={
                    <div className="tab-label-wrapper">
                      <p>作業報告</p>
                      {serviceCardData?.work_reports?.find(
                        (item: any) => item?.has_notification
                      ) && <img src={NotificationSmWhiteIcon} alt="" />}
                      {handleError(TAB_5) && (
                        <ErrorOutlineIcon htmlColor="#FF0000" />
                      )}
                    </div>
                  }
                />
                <Tab
                  value="6"
                  label={
                    <div className="tab-label-wrapper">
                      <p>支払情報</p>
                      {serviceCardData?.payment?.has_notification && (
                        <img src={NotificationSmWhiteIcon} alt="" />
                      )}
                      {/* <ErrorOutlineIcon htmlColor="#FF0000" /> */}
                    </div>
                  }
                />
                <Tab
                  value="7"
                  label={
                    <div className="tab-label-wrapper">
                      <p>作業費用</p>
                      {serviceCardData?.work_cost?.has_notification && (
                        <img src={NotificationSmWhiteIcon} alt="" />
                      )}
                      {handleError(TAB_7) && (
                        <ErrorOutlineIcon htmlColor="#FF0000" />
                      )}
                    </div>
                  }
                />
                <Tab
                  value="8"
                  label={
                    <div className="tab-label-wrapper">
                      <p>メモ</p>
                      {serviceCardData?.memo?.has_notification && (
                        <img src={NotificationSmWhiteIcon} alt="" />
                      )}
                    </div>
                  }
                />
              </TabList>
            </Box>
            <Box
              component="form"
              style={{
                width: "100%",
              }}
            >
              <TabPanel value="1">
                <Status {...props} />
              </TabPanel>
              <TabPanel value="2">
                <OrderInformation
                  {...props}
                  receptionOptions={receptionOptions}
                  filterKey={filterKey}
                  setFilterKey={setFilterKey}
                  isRedirectFromBuilding={isRedirectFromBuilding}
                />
              </TabPanel>
              <TabPanel value="3">
                <ContractorInformation
                  workerOptions={workerOptions}
                  phoneOptions={phoneOptions}
                  receptionOptions={receptionOptions}
                  setEmployeeInChargeId={setEmployeeInChargeId}
                  {...props}
                />
              </TabPanel>
              <TabPanel value="4">
                <RequestContent
                  {...props}
                  setRequestOrderDate={setRequestOrderDate}
                  setRequestStartDate={setRequestStartDate}
                  setRequestEndDate={setRequestEndDate}
                  workerOptions={workerOptions}
                  subcontractorOptions={subcontractorOptions}
                  isWorkerLoading={isWorkerLoading}
                />
              </TabPanel>
              <TabPanel value="5">
                <WorkReport {...props} />
              </TabPanel>
              <TabPanel value="6">
                <PaymentInformation
                  {...props}
                  paymentCategoryData={paymentCategoryData}
                  workerOptions={workerOptions}
                />
              </TabPanel>
              <TabPanel value="7">
                <WorkCost {...props} />
              </TabPanel>
              <TabPanel value="8">
                <Memo
                  {...props}
                  serviceCardData={serviceCardData}
                  messages={messages}
                  setMessages={setMessages}
                />
              </TabPanel>
            </Box>
          </TabContext>
        </div>
      </div>
      <ReactRouterPrompt when={navigatePrompt === "require"}>
        {({ isActive, onConfirm, onCancel }) => (
          <ModalConfirm
            isSubmitting={false}
            content="追加を破棄しますか?"
            isOpen={isActive}
            onClose={onCancel}
            onOk={() => Promise.resolve(onConfirm())}
          />
        )}
      </ReactRouterPrompt>
    </Styles>
  );
};

const ServiceCardControl: FC<ReceivedProps> = (props) => (
  <ServiceCardControlLayout {...useServiceCardControl(props)} />
);

export default ServiceCardControl;
