import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ColumnApi, GridApi, GridSizeChangedEvent } from "ag-grid-community";
import { get, pick } from "lodash";
import { FieldValues, UseFormGetValues } from "react-hook-form";

import { QUERY_NAME, SORTING } from "@bms/constants";
import {
  useDeletePaymentCategory,
  useFetchOutputs,
  useSubmitStatuses,
} from "@bms/hooks";
import { ServiceCard } from "@bms/types";
import SelectFloatingFilterComponent, { columns, SORT_PARAMS } from "./utility";
import dayjs from "dayjs";
import { formatDay } from "@bms/utility/index";
import { notification } from "antd";
import { useQueryClient } from "@tanstack/react-query";

export type ReceivedProps = {
  filterParams: any;
  gridApi: GridApi | undefined;
  setGridApi: Dispatch<SetStateAction<GridApi | undefined>>;
  getValues: UseFormGetValues<FieldValues>;
  setFilterParams: Dispatch<SetStateAction<any>>;
};

const useOutputList = (props: ReceivedProps) => {
  const gridRef: any = useRef();
  const queryClient = useQueryClient();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [total, setTotal] = useState<number>(0);
  const [rowData, setRowData] = useState<any[]>([]);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState<boolean>(false);
  const [sortParams, setSortParams] = useState<Record<string, any>>({});
  const [paymentIds, setPaymentIds] = useState<{ id: number }[]>([]);
  const [isClear, setIsClear] = useState<boolean>(false);

  const { mutate: statusesAction } = useSubmitStatuses();
  const {
    data: outPutsData,
    isSuccess,
    isLoading,
    isFetching,
  } = useFetchOutputs(page, pageSize, props.filterParams, sortParams);
  const { mutate, isLoading: isSubmitting } = useDeletePaymentCategory();

  const onSortChanged = ({ columnApi }: { columnApi: ColumnApi }) => {
    const columnState = columnApi.getColumnState();
    const cusParams: any = columnState.reduce(
      (arr, cur) => ({
        ...arr,
        [get(SORT_PARAMS, cur.colId)]:
          cur.sort === null ? SORTING.ASC : cur.sort,
      }),
      {}
    );

    setSortParams({
      ...sortParams,
      ...cusParams,
    });
    setPage(1);
  };
  const clearFilter = useCallback(() => {
    if (props.gridApi) {
      props.gridApi.setFilterModel(null);
      props.gridApi.onFilterChanged();
      props.gridApi.clearAggFuncs();
      queryClient.invalidateQueries([QUERY_NAME.OUT_PUTS]);
      gridRef?.current?.api.setFilterModel(null);
      setIsClear(true);
    }

    props.setFilterParams(
      pick(props.filterParams, [
        "status",
        "address_payment_type",
        "start_date",
        "end_date",
        "keyword",
      ])
    );
    setPage(1);
  }, [props, queryClient]);

  const onFilterUpdate = useCallback(() => {
    setIsClear(false);
    if (!props.gridApi) return;
    const model = props.gridApi.getFilterModel();

    const curParams = Object.keys(model)
      .filter(
        (item) =>
          !["order_date", "return_order_date", "order_date_search"].includes(
            item
          )
      )
      .reduce(
        (arr: any, cur: any) => ({
          ...arr,
          [cur]: model?.[cur]?.filter?.trim(),
        }),
        {}
      );

    // order_information - order_date
    const orderDateSearchField = model?.order_date_search
      ? {
          start_search_date: model?.order_date_search?.filter?.split("-")?.[0],
          end_search_date: model?.order_date_search?.filter?.split("-")?.[1],
        }
      : {};

    const orderDateField = model?.order_date
      ? {
          start_order_date: model?.order_date?.filter?.split("-")?.[0],
          end_order_date: model?.order_date?.filter?.split("-")?.[1],
        }
      : {};
    const returnDateField = model?.return_order_date
      ? {
          start_return_order_date:
            model?.return_order_date?.filter?.split("-")?.[0],
          end_return_order_date:
            model?.return_order_date?.filter?.split("-")?.[1],
        }
      : {};

    props.setFilterParams({
      ...{
        status: props.getValues("status"),
        address_payment_type: props.getValues("address_payment_type"),
        start_date: props.getValues("start_date")
          ? dayjs(props.getValues("start_date")).format("YYYY/MM/DD")
          : undefined,
        end_date: props.getValues("end_date")
          ? dayjs(props.getValues("end_date")).format("YYYY/MM/DD")
          : undefined,
        keyword: props.getValues("keyword") || undefined,
      },
      ...curParams,
      ...orderDateField,
      ...returnDateField,
      ...orderDateSearchField,
    });
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gridApi]);

  const onOpenModalDelete = () => {
    if (!props.gridApi) return;
    const rowSelected = props.gridApi.getSelectedRows();
    setPaymentIds(rowSelected.map((item) => ({ id: item.id })));
    setIsOpenModalDelete(true);
  };
  const onCloseModalDelete = () => setIsOpenModalDelete(false);

  const onDeleteRecord = async () => {
    mutate(
      {
        payload: { payment_category_attributes: paymentIds },
      },
      {
        onSuccess() {
          setRowData([]);
          setIsOpenModalDelete(false);
        },
      }
    );
  };

  const onGridReady = (params: GridSizeChangedEvent) => {
    props.setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const onStatusChange = async (data: any) => {
    const payload = pick(data, [
      "confirm_payment_status",
      "invoice_status",
      "working_status",
    ]);
    statusesAction(
      {
        payload: {
          ...payload,
          has_notification: true,
          service_card_id: data.id,
        },
        statusId: data?.status?.id,
      },
      {
        onSuccess({ data }) {
          // refetch();
        },
        onError(error: any) {
          notification["error"]({
            message: { error }?.error?.response?.data?.error || "Failed",
          });
        },
      }
    );
  };

  const columnDefsSetting: any = columns(onStatusChange).map((item, index) => {
    return {
      ...item,
      unSortIcon: ![0, 14].includes(index),
      ...(item?.field &&
        ["working_status", "invoice_status", "confirm_payment_status"].includes(
          item?.field
        ) && {
          floatingFilterComponent: SelectFloatingFilterComponent,
        }),
      filterParams: {
        textMatcher: () => {
          return true;
        },
      },
    };
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      setRowData([]);
    } else {
      if (isSuccess) {
        setRowData(
          (outPutsData.data as ServiceCard[]).map((item) => {
            return {
              ...item,
              order_date: item?.request_content?.order_date,
              return_order_date: item?.request_content?.return_order_date,
              building_name:
                item?.order_information?.building_data?.building_name || "",
              owner_name:
                item?.order_information?.building_data?.owner_name || "",
              resident_name:
                item?.order_information?.building_data?.resident_name || "",
              resident_contact:
                item?.order_information?.building_data?.resident_contact || "",
              working_status: item?.status?.working_status || "",
              invoice_status: item?.status?.invoice_status || "",
              paid_at: item?.status?.paid_at || "",
              billing_amount: item?.work_cost?.total
                ? item?.work_cost?.total
                : "",
              confirm_payment_status:
                item?.status?.confirm_payment_status || "",
              billing_address:
                item?.order_information?.address_payment_type?.[0] ===
                "オーナー"
                  ? item?.order_information?.building_data?.owner_address
                  : item?.order_information?.address_payment_type?.[0] ===
                    "契約者"
                  ? item?.order_information?.building_data?.contractor_address
                  : "",
              department_in_charge:
                item?.request_content?.workers?.length > 0
                  ? item?.request_content?.workers[0]?.name
                  : "",
              order_date_search: item.order_information?.order_date
                ? `${dayjs(item.order_information?.order_date).format(
                    "YYYY/MM/DD"
                  )}(${formatDay(item.order_information?.order_date)})`
                : "",
            };
          })
        );
        setTotal(outPutsData.meta.total_records);
      }
    }
  }, [isFetching, isLoading, isSuccess, outPutsData]);

  useEffect(() => {
    if (isSuccess) {
      setIsClear(false);
    }
  }, [isSuccess]);

  return {
    ...props,
    rowData,
    pageSize,
    gridRef,
    page,
    isOpenModalDelete,
    isSubmitting,
    total,
    columnDefs: columnDefsSetting,
    isLoading,
    isFetching,
    onSortChanged,
    onFilterUpdate,
    onDeleteRecord,
    onOpenModalDelete,
    onCloseModalDelete,
    setPage,
    onGridReady,
    setPageSize,
    clearFilter,
    isClear,
  };
};

export type Props = ReturnType<typeof useOutputList>;

export default useOutputList;
