import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_NAME, ROUTES, SERVICES, SORTING } from "@bms/constants";
import axios from "@bms/libs/axios";
import { useNavigate } from "react-router-dom";

export const useFetchServiceCard = (
  page?: number,
  pageSize?: number,
  filterParams?: any,
  sortParams?: any
) => {
  for (const obj in sortParams) {
    if (sortParams[obj] === SORTING.ASC) {
      delete sortParams[obj];
    }
  }

  return useQuery(
    [QUERY_NAME.SERVICE_CARDS, page, pageSize, filterParams, sortParams],
    () =>
      axios({
        method: "GET",
        url: SERVICES.SERVICE_CARDS,
        params: {
          page: page,
          per_page: pageSize,
          ...filterParams,
          ...sortParams,
        },
      }).then((res) => {
        return res.data;
      }),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );
};

export const useFetchOneServiceCard = (serviceCardId: any) => {
  const id = serviceCardId === "create" ? null : serviceCardId;
  return useQuery(
    [QUERY_NAME.SERVICE_CARDS_ID, serviceCardId],
    () =>
      axios({
        method: "GET",
        url: `${SERVICES.SERVICE_CARDS}/${serviceCardId}`,
      }).then((res) => res.data),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!id,
    }
  );
};

export const useCreateServiceCard = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    () =>
      axios({
        method: "POST",
        url: SERVICES.SERVICE_CARDS,
      }),
    {
      onSuccess({ data }) {
        navigate(`${ROUTES.CREATE}/${data.id}`);
        queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
      },
    }
  );
};

export const useDuplicateServiceCard = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id }: any) =>
      axios({
        method: "PUT",
        url: `${SERVICES.SERVICE_CARDS}/${id}/duplicate`,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
      },
    }
  );
};

export const useDeleteServiceCard = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload }: any) =>
      axios({
        method: "DELETE",
        url: `${SERVICES.SERVICE_CARDS}/delete`,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
      },
    }
  );
};

export const useSendMessageServiceCard = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, id }: any) =>
      axios({
        method: "PUT",
        url: `${SERVICES.SERVICE_CARDS}/${id}`,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
      },
    }
  );
};

export const useSubmitStatuses = (isQueryClient?: boolean) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, statusId }: any) =>
      axios({
        method: statusId ? "PUT" : "POST",
        url: statusId ? `${SERVICES.STATUSES}/${statusId}` : SERVICES.STATUSES,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.STATUSES]);
        queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
        if (!isQueryClient) {
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS_ID]);
        }
      },
    }
  );
};

export const useSubmitOrderInformation = (isQueryClient?: boolean) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, orderInfoId }: any) =>
      axios({
        method: orderInfoId ? "PUT" : "POST",
        url: orderInfoId
          ? `${SERVICES.ORDER_INFORMATIONS}/${orderInfoId}`
          : SERVICES.ORDER_INFORMATIONS,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.ORDER_INFORMATIONS]);
        if (!isQueryClient) {
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS_ID]);
        }
      },
    }
  );
};

export const useSubmitContractorInformation = (isQueryClient?: boolean) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, contractorId }: any) =>
      axios({
        method: contractorId ? "PUT" : "POST",
        url: contractorId
          ? `${SERVICES.CONTRACTOR_INFORMATIONS}/${contractorId}`
          : SERVICES.CONTRACTOR_INFORMATIONS,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.CONTRACTOR_INFORMATIONS]);
        if (!isQueryClient) {
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS_ID]);
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
        }
      },
    }
  );
};

export const useCheckWorkerLeave = (
  employeeId?: number,
  byOrderDate?: string
) => {
  return useQuery(
    ["check_leave_status", employeeId, byOrderDate],
    async () => {
      if (!employeeId || !byOrderDate) return null;
      const response = await axios.get(
        `${SERVICES.CONTRACTOR_INFORMATIONS}/check_leave_on_date`,
        {
          params: {
            employee_in_charge_id: employeeId,
            by_order_date: byOrderDate,
          },
        }
      );
      return response.data;
    },
    {
      enabled: !!employeeId && !!byOrderDate,
      retry: false,
      staleTime: Infinity,
    }
  );
};

export const useSubmitRequestContent = (isQueryClient?: boolean) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, requestContentId }: any) =>
      axios({
        method: requestContentId ? "PUT" : "POST",
        url: requestContentId
          ? `${SERVICES.REQUEST_CONTENTS}/${requestContentId}`
          : SERVICES.REQUEST_CONTENTS,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.REQUEST_CONTENTS]);
        if (!isQueryClient) {
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS_ID]);
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
        }
      },
    }
  );
};

export const useSubmitReturnDateHistory = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, returnDateHistoryId }: any) =>
      axios({
        method: returnDateHistoryId ? "PUT" : "POST",
        url: returnDateHistoryId
          ? `${SERVICES.RETURN_DATE_HISTORY}/${returnDateHistoryId}`
          : SERVICES.RETURN_DATE_HISTORY,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.REQUEST_CONTENTS]);
        queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS_ID]);
        queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
      },
    }
  );
};

export const useSubmitWorkReport = (isQueryClient?: boolean) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, workReportId }: any) => {
      return axios({
        method: workReportId ? "PUT" : "POST",
        url: workReportId
          ? `${SERVICES.WORK_REPORTS}/update`
          : SERVICES.WORK_REPORTS,
        data: payload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.WORK_REPORTS]);
        if (!isQueryClient) {
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS_ID]);
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
        }
      },
    }
  );
};

export const useSubmitWorkCost = (isQueryClient?: boolean) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, workCostsId }: any) =>
      axios({
        method: workCostsId ? "PUT" : "POST",
        url: workCostsId
          ? `${SERVICES.WORK_COSTS}/${workCostsId}`
          : SERVICES.WORK_COSTS,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.WORK_COSTS]);
        if (!isQueryClient) {
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS_ID]);
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
        }
      },
    }
  );
};

export const useSubmitPayment = (isQueryClient?: boolean) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, paymentId }: any) =>
      axios({
        method: paymentId ? "PUT" : "POST",
        url: paymentId
          ? `${SERVICES.PAYMENTS}/${paymentId}`
          : SERVICES.PAYMENTS,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.PAYMENTS]);
        if (!isQueryClient) {
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS_ID]);
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
        }
      },
    }
  );
};

export const useSubmitMemo = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload, memoId }: any) =>
      axios({
        method: memoId ? "PUT" : "POST",
        url: memoId ? `${SERVICES.MEMO}/${memoId}` : SERVICES.MEMO,
        data: payload,
      }),
    {
      onSuccess() {
        queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS_ID]);
        queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
      },
    }
  );
};

export const useSendMessage = (isQueryClient?: boolean) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ payload }: any) =>
      axios({
        method: "POST",
        url: SERVICES.MESSAGES,
        data: payload,
      }),
    {
      onSuccess() {
        // queryClient.invalidateQueries([QUERY_NAME.MESSAGES]);
        if (!isQueryClient) {
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS_ID]);
          queryClient.invalidateQueries([QUERY_NAME.SERVICE_CARDS]);
        }
      },
    }
  );
};

export const useFetchMessages = (
  memoId: number | string | undefined,
  serviceCardId?: any
) => {
  return useQuery(
    [QUERY_NAME.MESSAGES, memoId, serviceCardId],
    () =>
      axios({
        method: "GET",
        url: SERVICES.MESSAGES,
        params: {
          memo_id: memoId,
          service_card_id: serviceCardId,
          page: 1,
          per_page: 100000,
        },
      }).then((res) => res.data),
    {
      enabled: !!memoId,
    }
  );
};

export const useFetchScheduleServiceCard = (
  date: string,
  workerId: string | undefined
) => {
  return useQuery(
    [QUERY_NAME.SCHEDULES, date],
    () =>
      axios({
        method: "GET",
        url: SERVICES.SCHEDULES,
        params: {
          date,
          worker_id: workerId,
        },
      }).then((res) => {
        return res.data.json;
      }),
    {
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
    }
  );
};
