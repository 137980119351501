import { useCallback, useEffect } from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";

import { SelectOptionsType, ServiceCard, Workers } from "@bms/types";

export type ReceivedProps = {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  errors: FieldErrors<FieldValues>;
  control: Control<FieldValues>;
  isView: boolean;
  serviceCardData: ServiceCard;
  workerOptions: SelectOptionsType[];
  phoneOptions: SelectOptionsType[];
  receptionOptions: SelectOptionsType[];
  workersDataOriginal: Workers[];
  workersOfficeDataOriginal: Workers[];
  setEmployeeInChargeId: any;
};

const useContractorInformation = (props: ReceivedProps) => {
  const handleContactPerson = useCallback(
    () =>
      props.workersDataOriginal.filter(
        (item) => item.id === props.watch("reception_staff_id")
      ),
    [props]
  );
  const handleSubContactPerson = useCallback(
    () =>
      props.workersOfficeDataOriginal.filter(
        (item) => item.id === props.watch("employee_in_charge_id")
      ),
    [props]
  );

  useEffect(() => {
    handleContactPerson();
  }, [handleContactPerson]);

  useEffect(() => {
    handleSubContactPerson();
  }, [handleSubContactPerson]);

  return {
    ...props,
    handleContactPerson,
    handleSubContactPerson,
  };
};

export type Props = ReturnType<typeof useContractorInformation>;

export default useContractorInformation;
